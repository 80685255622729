/*-------- Select2 -------*/
.select2-container {
	box-sizing: border-box;
	display: inline-block;
	margin: 0;
	position: relative;
	vertical-align: middle;
	.select2-selection--single {
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		height: 28px;
		user-select: none;
		-webkit-user-select: none;
		.select2-selection__rendered {
			display: block;
			padding-left: 0.75rem;
			padding-right: 1.5rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
.item2-gl-nav .select2-container .select2-selection--single .select2-selection__rendered {
	float: left;
}
.select2-container {
	.select2-selection--single .select2-selection__clear {
		position: relative;
	}
	&[dir="rtl"] .select2-selection--single .select2-selection__rendered {
		padding-right: 8px;
		padding-left: 20px;
	}
	.select2-selection--multiple {
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		min-height: 40px;
		user-select: none;
		-webkit-user-select: none;
		.select2-selection__rendered {
			display: inline-block;
			overflow: hidden;
			padding-left: 8px;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.select2-search--inline {
		float: left;
		display: none;
		.select2-search__field {
			box-sizing: border-box;
			border: none;
			font-size: 100%;
			margin-top: 5px;
			padding: 0;
			&::-webkit-search-cancel-button {
				-webkit-appearance: none;
			}
		}
	}
}
.select2-dropdown {
	background-color: white;
	border: 1px solid $border-color;
	border-radius: 4px;
	box-sizing: border-box;
	display: block;
	position: absolute;
	left: -100000px;
	width: 100%;
	z-index: 1051;
	padding: 5px;
	box-shadow: 0px 4px 12px 4px rgba(49, 45, 101, 0.2);
}
.select2-results {
	display: block;
}
.select2-results__options {
	list-style: none;
	margin: 0;
	padding: 0;
}
.select2-results__option {
	padding: 6px;
	user-select: none;
	-webkit-user-select: none;
	&[aria-selected] {
		cursor: pointer;
	}
}
.select2-container--open {
	.select2-dropdown {
		left: 0;
	}
	.select2-dropdown--above {
		border-bottom: none;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	.select2-dropdown--below {
		border-top: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}
.select2-search--dropdown {
	display: block;
	padding: 4px;
	.select2-search__field {
		padding: 4px;
		width: 100%;
		box-sizing: border-box;
		&::-webkit-search-cancel-button {
			-webkit-appearance: none;
		}
	}
	&.select2-search--hide {
		display: none;
	}
}
.select2-close-mask {
	border: 0;
	margin: 0;
	padding: 0;
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	z-index: 99;
	background-color: #fff;
	filter: alpha(opacity=0);
}
.select2-hidden-accessible {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 100% !important;
	white-space: nowrap !important;
}
.select2-container--default {
	.select2-selection--single {
		background-color: $form-background;
		border: 1px solid #aaa;
		border-radius: 4px;
		.select2-selection__rendered {
			color: #444;
			line-height: 28px;
		}
		.select2-selection__clear {
			cursor: pointer;
			float: right;
			font-weight: bold;
		}
		.select2-selection__placeholder {
			color: #999;
		}
		.select2-selection__arrow {
			height: 26px;
			position: absolute;
			top: 1px;
			right: 1px;
			width: 20px;
			b {
				border-color: #605e7e transparent transparent transparent;
				border-style: solid;
				border-width: 5px 4px 0 4px;
				height: 0;
				left: 50%;
				margin-left: -12px;
				margin-top: -2px;
				position: absolute;
				top: 50%;
				width: 0;
			}
		}
	}
	&[dir="rtl"] .select2-selection--single {
		.select2-selection__clear {
			float: left;
		}
		.select2-selection__arrow {
			left: 1px;
			right: auto;
		}
	}
	&.select2-container--disabled .select2-selection--single {
		background-color: #eee;
		cursor: default;
		.select2-selection__clear {
			display: none;
		}
	}
	&.select2-container--open .select2-selection--single .select2-selection__arrow b {
		border-color: transparent transparent #605e7e transparent;
		border-width: 0 4px 5px 4px;
	}
	.select2-selection--multiple {
		background-color: white;
		border: 1px solid #aaa;
		border-radius: 4px;
		cursor: text;
		.select2-selection__rendered {
			box-sizing: border-box;
			list-style: none;
			margin: 0;
			padding: 0 5px;
			width: 100%;
			li {
				list-style: none;
			}
		}
		.select2-selection__placeholder {
			color: #999;
			margin-top: 5px;
			float: left;
		}
		.select2-selection__clear {
			cursor: pointer;
			float: right;
			font-weight: bold;
			margin-top: 5px;
			margin-right: 10px;
		}
		.select2-selection__choice {
			background-color: #e4e4e4;
			border: 1px solid #aaa;
			border-radius: 4px;
			cursor: default;
			float: left;
			margin-right: 5px;
			margin-top: 5px;
			padding: 0 5px;
		}
		.select2-selection__choice__remove {
			color: #999;
			cursor: pointer;
			display: inline-block;
			font-weight: bold;
			margin-right: 2px;
			outline: 0;
			border: 0;
			background: transparent;
			&:hover {
				color: #333;
			}
		}
	}
	&[dir="rtl"] .select2-selection--multiple {
		.select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
			float: right;
		}
		.select2-selection__choice {
			margin-left: 5px;
			margin-right: auto;
		}
		.select2-selection__choice__remove {
			margin-left: 2px;
			margin-right: auto;
		}
	}
	&.select2-container--focus .select2-selection--multiple {
		border: solid black 1px;
		outline: 0;
	}
	&.select2-container--disabled {
		.select2-selection--multiple {
			background-color: #eee;
			cursor: default;
		}
		.select2-selection__choice__remove {
			display: none;
		}
	}
	&.select2-container--open {
		&.select2-container--above {
			.select2-selection--single, .select2-selection--multiple {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
		&.select2-container--below {
			.select2-selection--single, .select2-selection--multiple {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
	.select2-search--dropdown .select2-search__field {
		border: 1px solid #aaa;
	}
	.select2-search--inline .select2-search__field {
		background: transparent;
		border: none;
		outline: 0;
		box-shadow: none;
		-webkit-appearance: textfield;
	}
	.select2-results>.select2-results__options {
		max-height: 200px;
		overflow-y: auto;
	}
	.select2-results__option {
		&[role=group] {
			padding: 0;
		}
		&[aria-disabled=true] {
			color: #999;
		}
		&[aria-selected=true] {
			background-color: #f0f3fa;
		}
		.select2-results__option {
			padding-left: 1em;
			height: 38px;
			line-height: 28px;
			.select2-results__group {
				padding-left: 0;
			}
			.select2-results__option {
				margin-left: -1em;
				padding-left: 2em;
				.select2-results__option {
					margin-left: -2em;
					padding-left: 3em;
					.select2-results__option {
						margin-left: -3em;
						padding-left: 4em;
						.select2-results__option {
							margin-left: -4em;
							padding-left: 5em;
							.select2-results__option {
								margin-left: -5em;
								padding-left: 6em;
							}
						}
					}
				}
			}
		}
	}
	.select2-results__group {
		cursor: default;
		display: block;
	}
}
.select2-container--classic {
	.select2-selection--single {
		background-color: #f7f7f7;
		border: 1px solid #aaa;
		border-radius: 4px;
		outline: 0;
		background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
		background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
		background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
		background-repeat: repeat-x;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
		&:focus {
			border: 1px solid #5897fb;
		}
		.select2-selection__rendered {
			color: #444;
			line-height: 28px;
		}
		.select2-selection__clear {
			cursor: pointer;
			float: right;
			font-weight: bold;
			margin-right: 10px;
		}
		.select2-selection__placeholder {
			color: #999;
		}
		.select2-selection__arrow {
			background-color: #ddd;
			border: none;
			border-left: 1px solid #aaa;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			height: 26px;
			position: absolute;
			top: 1px;
			right: 1px;
			width: 20px;
			background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
			background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
			background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
			background-repeat: repeat-x;
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
			b {
				border-color: #888 transparent transparent transparent;
				border-style: solid;
				border-width: 5px 4px 0 4px;
				height: 0;
				left: 50%;
				margin-left: -4px;
				margin-top: -2px;
				position: absolute;
				top: 50%;
				width: 0;
			}
		}
	}
	&[dir="rtl"] .select2-selection--single {
		.select2-selection__clear {
			float: left;
		}
		.select2-selection__arrow {
			border: none;
			border-right: 1px solid #aaa;
			border-radius: 0;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			left: 1px;
			right: auto;
		}
	}
	&.select2-container--open {
		.select2-selection--single {
			border: 1px solid #5897fb;
			.select2-selection__arrow {
				background: transparent;
				border: none;
				b {
					border-color: transparent transparent #888 transparent;
					border-width: 0 4px 5px 4px;
				}
			}
		}
		&.select2-container--above .select2-selection--single {
			border-top: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
			background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
			background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
			background-repeat: repeat-x;
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
		}
		&.select2-container--below .select2-selection--single {
			border-bottom: none;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
			background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
			background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
			background-repeat: repeat-x;
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
		}
	}
	.select2-selection--multiple {
		background-color: white;
		border: 1px solid #aaa;
		border-radius: 4px;
		cursor: text;
		outline: 0;
		&:focus {
			border: 1px solid #5897fb;
		}
		.select2-selection__rendered {
			list-style: none;
			margin: 0;
			padding: 0 5px;
		}
		.select2-selection__clear {
			display: none;
		}
		.select2-selection__choice {
			background-color: #e4e4e4;
			border: 1px solid #aaa;
			border-radius: 4px;
			cursor: default;
			float: left;
			margin-right: 5px;
			margin-top: 5px;
			padding: 0 5px;
		}
		.select2-selection__choice__remove {
			color: #888;
			cursor: pointer;
			display: inline-block;
			font-weight: bold;
			margin-right: 2px;
			&:hover {
				color: #555;
			}
		}
	}
	&[dir="rtl"] .select2-selection--multiple {
		.select2-selection__choice {
			float: right;
			margin-left: 5px;
			margin-right: auto;
		}
		.select2-selection__choice__remove {
			margin-left: 2px;
			margin-right: auto;
		}
	}
	&.select2-container--open {
		.select2-selection--multiple {
			border: 1px solid #5897fb;
		}
		&.select2-container--above .select2-selection--multiple {
			border-top: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		&.select2-container--below .select2-selection--multiple {
			border-bottom: none;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	.select2-search--dropdown .select2-search__field {
		border: 1px solid #aaa;
		outline: 0;
	}
	.select2-search--inline .select2-search__field {
		outline: 0;
		box-shadow: none;
	}
	.select2-dropdown {
		background-color: white;
		border: 1px solid transparent;
	}
	.select2-dropdown--above {
		border-bottom: none;
	}
	.select2-dropdown--below {
		border-top: none;
	}
	.select2-results>.select2-results__options {
		max-height: 200px;
		overflow-y: auto;
	}
	.select2-results__option {
		&[role=group] {
			padding: 0;
		}
		&[aria-disabled=true] {
			color: grey;
		}
	}
	.select2-results__option--highlighted[aria-selected] {
		background-color: #3875d7;
		color: white;
	}
	.select2-results__group {
		cursor: default;
		display: block;
		padding: 6px;
	}
	&.select2-container--open .select2-dropdown {
		border-color: #5897fb;
	}
}
/*-------- Select2 -------*/


.select-languages, .select-currency {
	.select2-container .select2-selection--single {
		height: 1.4rem !important;
		color: #cecde0 !important;
		border-radius: 2px;
		background: 0 0 !important;
		border: 0 !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 20.5px !important;
		}
		.select2-selection__arrow {
			top: -2px !important;
		}
	}
	.select2-container {
		width: 138px !important;
	}
	.select2-results {
		color: #cecde0 !important;
	}
}
.select-country {
	.select2-container {
		width: 150px !important;
		.select2-selection--single {
			height: 1.4rem !important;
			color: #cecde0 !important;
			border-radius: 2px;
			background: 0 0 !important;
			border: 0 !important;
		}
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 40px !important;
			padding-left: 20px !important;
		}
		.select2-selection__arrow {
			top: -3px !important;
		}
	}
	.select2-results {
		color: #cecde0 !important;
	}
	.select2-container .select2-selection--single .select2-selection__rendered {
		padding-left: 3px;
	}
}
.select2-results__option {
	font-size: 13px;
}

.header-select2{
	.select2-container--default .select2-selection--single{
		background:transparent;
		height:25px !important;
		border:0 !important;
		.select2-selection__rendered{
			color:#fff !important;
			line-height:25px !important;
		}
	}
}

.index-search-select .select2 {
	width: 100% !important;
}
.select2-container .select2-selection--single {
	height: 40px !important;
}
.select2.select2-container.select2-container--default.select2-container--below.select2-container--open {
	width: 100%;
}
.header-text1 {	
	.select2-container--default .select2-selection--single {
		border: 1px solid #fff !important;
	}
}

.my-select .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 100%;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 100% !important;
}

.top-bar {
	.select2-dropdown.select2-dropdown--below {
		width: 180px !important;
	}
	.select-country .select2-container {
		width: 150px !important;
		height:40px;
	}
	.select-country {
		padding:0 !important;
	}
}

.app-header-background .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: rgb(255, 255, 255) !important;
}
.header-main {
	.top-bar.app-header-background .contact.border-left {
		border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
	}
	.app-header-background .select2-container--default .select2-selection--single .select2-selection__arrow b {
		border-color: rgb(255, 255, 255) transparent transparent transparent;
	}
}

.select2-container--default .select2-results__group {
	margin: 7px 0;
}

footer .select2-container--default .select2-selection--single {
	.select2-selection__rendered {
		color: #fff !important;
		line-height: 39px !important;
	}
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.footer-light footer .select2-container--default .select2-selection--single {
	.select2-selection__rendered {
		color: $text-color !important;
		line-height: 39px !important;
	}
	background: #fff;
	border: 1px solid $border-color !important;
}

.select2-container--default {
	.select2-selection--single .select2-selection__arrow b {
		border-color: #dfdcf3 transparent transparent transparent !important;
	}
	&.select2-container--open .select2-selection--single .select2-selection__arrow b {
		border-color: transparent transparent #dfdcf3 transparent !important;
	}
}
.header-absolute {	
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		color: rgba(255, 255, 255, 0.8) !important;
	}
}
.header-main.header-absolute {	
	.select2-container--default .select2-selection--single .select2-selection__arrow b {
		border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
	}
}