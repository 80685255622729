@import "variables";
@import "plugins/daterangepicker";
@import "plugins/flags";
@import "plugins/gallery";
@import "plugins/jquery.timepicker";
@import "plugins/owl.carousel";
@import "plugins/popup-chat";
@import "plugins/rangeslider";
@import "plugins/select2.min";
@import "plugins/uidate-picker";
@import "plugins/vector-map";